import {acceptHMRUpdate, defineStore, storeToRefs} from 'pinia';
import {computed, ref} from 'vue';
import {useRuntimeConfig} from '#app';
import type {ProblemDetail} from '~/lib/types/fetchErrors';
import useApi from '~/composables/useApi';
import type {components} from '~/lib/types/openapi';
import {useCompanyContextStore} from '~/store/company/companyContext';

export const useUserDetailsStore = defineStore('userDetails', () => {
	const companyContextStore = useCompanyContextStore();

	const loading = ref<boolean>(true);
	const problemDetail = ref<ProblemDetail>();

	const userDetails = ref<components['schemas']['UserDetails']>();

	const clearUserDetails = () => {
		userDetails.value = undefined;
		loading.value = false;
		problemDetail.value = undefined;
	};

	const fetchUserDetails = async () => {
		const {GET} = useApi();
		loading.value = true;
		problemDetail.value = undefined;

		const {data, error} = await GET(`/user`);

		if (error || !data) {
			loading.value = false;
			problemDetail.value = error;
			return false;
		}

		userDetails.value = data;
		loading.value = false;
		return true;
	};

	const runtimeConfig = useRuntimeConfig();

	const isAllowedToManageCompanies = computed(() => {
		if (runtimeConfig.public.type === 'stigas') {
			return false;
		}

		return userDetails.value?.applicationRoles?.includes('APPLICATION_ADMIN') ?? false;
	});

	const {writeAccessForCurrentCompany, writeAccessForCurrentRie} = storeToRefs(companyContextStore);
	const isAllowedToWriteToCompany = computed(() => writeAccessForCurrentCompany.value);
	const isAllowedToWriteToRie = computed(() => writeAccessForCurrentRie.value);

	return {
		clearUserDetails,
		fetchUserDetails,
		isAllowedToManageCompanies,
		isAllowedToWriteToCompany,
		isAllowedToWriteToRie,
		loading
	};

});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useUserDetailsStore, import.meta.hot));
}
